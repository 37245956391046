import colegeTitle from "../assets/certificates-images/titulo-colegio.jpg"
import instituteTitle from "../assets/certificates-images/titulo-instituto.jpg"
import maschaCertificate from "../assets/certificates-images/certificado-mashca-bots.jpg"
import innovaProject from "../assets/certificates-images/certificado-proyectos-inovadores.jpg"
import cssCertificate from "../assets/certificates-images/css-certificate-fcc.jpg"

import "../stylesheets/Certificates.css"


function Certificates({className, trigger}){
    return(
        <section className={className} id="certificates">
        <div className="container section-container certificates-container">
          <h1>Certificados</h1>
          <div className="certificates-items-container">
            
            <div className={"certificate-item" + (trigger ? " certificate-item-animation" : "")}>
                <img className="certificate-item-image" src={colegeTitle} alt="Colege certificate"/>
            </div>

            <div className={"certificate-item" + (trigger ? " certificate-item-animation" : "")}>
                <img className="certificate-item-image" src={instituteTitle} alt="Institute certificate"/>
            </div>

            <div className={"certificate-item" + (trigger ? " certificate-item-animation" : "")}>
                <img className="certificate-item-image" src={maschaCertificate} alt="Mashca bots certificate"/>
            </div>

            
            <div className={"certificate-item" + (trigger ? " certificate-item-animation" : "")}>
         
                <img className="certificate-item-image" src={innovaProject} alt="Innova project certificate"/>
            </div>

            <div className={"certificate-item" + (trigger ? " certificate-item-animation" : "")}>
         
                <img className="certificate-item-image" src={cssCertificate} alt="CSS Free code camp certificate"/>
            </div>

          </div>
          
        </div>
      </section>
    )
}

export default Certificates