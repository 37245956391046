import Project from "./Project";
import "../stylesheets/ProjectsItemsContainer.css"
import {projectsDb, projectsListsDb} from "../data/projectsDatabase";

function ProjectsItemContainer({trigger}) {
  return (
    <>
      <h2>Informática</h2>
    <div className={"projects-items-container" + (trigger ? " projects-items-container-animation" : "")}>
      {
        projectsDb.map(e => <Project key={e.title} src={e.src} title ={e.title} text ={e.text} url={e.url} buttonText={e.btnText} /> )
      }
    </div>
    <h2>Electrónica</h2>
    <div className={"projects-items-container projects-items-container__right" + (trigger ? " projects-items-container-animation__right" : "")}>
      {
        projectsListsDb.map(e => <Project key={e.title} src={e.src} title ={e.title} text ={e.text} url={e.url} buttonText={e.btnText} /> )
      }
    </div>
    </>
  );
}

export default ProjectsItemContainer;
