import SocialIcons from "./SocialIcons";
import("../stylesheets/Footer.css");
export function Footer(){
    return(
        <footer className="footer" id="contact">
            <div className="footer-container container">
                <h1 className="footer-title">Contacto</h1>
                <div className="footer-icons-container">
                <SocialIcons/>
                </div>

            </div>
        </footer>
    )

}

export default Footer