
import ProjectsItemContainer from "./ProjectsItemsContainer";
import "../stylesheets/Projects.css"

function Projects({className,trigger}) {
  return (
    <section className={className} id="projects">
      <div className="container section-container projects-container">
        <h1>Proyectos</h1>
        <ProjectsItemContainer trigger={trigger} />
      </div>
    </section>
  );
}

export default Projects;
