import"../stylesheets/Hero.css"
import AnimatedWord from "./AnimatedWords";

function Hero({className,trigger}) {

 
  return (
    <section className={className} >
      <div className="hero-content container">
              <p className="hero-title">Hola. Soy Esteban Carrillo <br/>{ trigger ? <AnimatedWord addContainer={true} words={["programador.   ", "tecnologo en electronica.    " ,"ciclista aficionado.   ", "autodidacta.    "]} delay={150}/>: ""} </p>
              <p className="hero-paragraph">
                Bienvenido a mi portfolio.
              </p>
              <a className="hero-cta" href="#about">Sobre mi</a>
      </div>
    </section>
  );
}

export default Hero;
