
import "../stylesheets/Project.css";
import CtaProjectButton from "./CtaProjectButton";

function Project({src, title,url,text,buttonText="Ver proyecto"}) {
  return (
    <div className="project-item">
      <figure className="project-item-figure">
        <img src={src} alt="" />
      </figure>
      <div className="project-item-content">
        <h3>{title}</h3>
        <p>
          {text}
        </p>
        <CtaProjectButton url={url} text={buttonText}/>
      </div>
    </div>
  );
}

export default Project