import { useEffect, useState } from "react";
import "./App.css";
import About from "./components/AboutMe";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Nav from "./components/Nav";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Certificates from "./components/Certificates";

function App() {
  const [heroAnimation, setHeroAnimation] = useState(true);
  const [aboutAnimation, setAboutAnimation] = useState(false);
  const [skillsAnimation, setSkillsAnimation] = useState(false);
  const[projectsAnimation,setProjectsAnimation]=useState(false);
  const[certificatesAnimation,setCertificatesAnimation]=useState(false);

  useEffect(() => {
    const hero = document.querySelector(".hero-section");
    const about = document.querySelector(".about-me-section");
    const skills = document.querySelector(".skills-section");
    const projects = document.querySelector(".projects-section");
    const certificates = document.querySelector(".certificates-section");

    const handleScroll = (event) => {
      let heroPosition = hero.getBoundingClientRect();
      let aboutPosition = about.getBoundingClientRect();
      let skillsPosition = skills.getBoundingClientRect();
      let projectsPosition= projects.getBoundingClientRect();
      let certificatesPosition= certificates.getBoundingClientRect();


      heroPosition.top > -heroPosition.height
        ? setHeroAnimation(true)
        : setHeroAnimation(false);

      aboutPosition.top < heroPosition.height / 1.5 && aboutPosition.top > -aboutPosition.height
        ? setAboutAnimation(true)
        : setAboutAnimation(false);

      skillsPosition.top < aboutPosition.height / 1.7 && skillsPosition.top > -skillsPosition.height
        ? setSkillsAnimation(true)
        : setSkillsAnimation(false);

      projectsPosition.top < skillsPosition.height / 1.5 && projectsPosition.top > -projectsPosition.height
        ? setProjectsAnimation(true)
        : setProjectsAnimation(false);
      
      certificatesPosition.top < projectsPosition.height / 3.0 && certificatesPosition.top > -certificatesPosition.height
        ? setCertificatesAnimation(true)
        : setCertificatesAnimation(false);
      
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="App">
      <Nav className="nav" />
      <Hero className="hero-section" trigger={heroAnimation} />
      <hr className="hr" />
      <About className="about-me-section" trigger={aboutAnimation} />
      <hr className="hr" />
      <Skills className="skills-section" trigger={skillsAnimation} />
      <hr className="hr" />
      <Projects className="projects-section" trigger={projectsAnimation}/>
      <hr className="hr" />
      <Certificates className="certificates-section" trigger={certificatesAnimation}/>
      <hr className="hr" />
      <Footer />
    </div>
  );
}

export default App;
