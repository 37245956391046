import webButtonsGen from "../assets/projects-images/generador-de-botones-css.png";
import edeptec from "../assets/projects-images/edeptec.png";
import ahorcado from "../assets/projects-images/juego del ahorcado.png";
import toDoList from "../assets/projects-images/to-do-list-vanilla-js.jpg";
import arduinoProjects from "../assets/projects-images/Proyectos con arduino portfolio img.jpg";
import picProjects from "../assets/projects-images/Proyectos con pic portfolio img.jpg";
import visualBasic from "../assets/projects-images/proyectos-visual-basic-portfolio.jpg";
import gallery from "../assets/projects-images/Galeria de diseños.png";
import traslator from "../assets/projects-images/clave-murcielago-a-español.jpg";
import passWordGenerator from "../assets/projects-images/password-generator-interface-2.jpg";

const projectsDb = [
  {
    title: "Generador de Botones para web",
    src: webButtonsGen,
    text: "Generador de estilos para botones web desarrollado con HTML,CSS y jQuery.",
    url: "https://buttongen.edeptec.com/",
  },
  {
    title: "EDEPTEC",
    src: edeptec,
    text: "Blog personal alojado en blogger, desarrollado con HTML, CSS y JavaScript.",
    url: "https://www.edeptec.com",
  },
  {
    title: "Juego del ahorcado",
    src: ahorcado,
    text: "Juego del ahorcado, desarrollado con React JS",
    url: "https://ahorcado.edeptec.com",
  },
  {
    title: "To Do List",
    src: toDoList,
    text: "To Do List desarrollado con HTML, CSS y vanilla javaScript. Con almacenamiento de tareas en local storage.",
    url: "https://estebancarrillog.github.io/to-do-list",
  },
  {
    title: "Galeria de diseños",
    src: gallery,
    text: "Galeria de diseños realizados con photoshop y canva.",
    url: "https://estebancarrillog.github.io/designs-gallery",
  },
  {
    title: "Traductor de clave murcielago",
    src: traslator,
    text: "Traductor de clave murcielago a español y viceversa desarrollado con React JS",
    url: "https://clavemurcielago.edeptec.com/",
  },
  {
    title: "Generador de Contraseñas",
    src: passWordGenerator,
    text: "Generador de contraseñas seguras desarrollado con HTML, CSS y JavaScript",
    url: "https://passwordgenerator.edeptec.com/",
  },
];

const projectsListsDb = [
  {
    title: "Proyectos en Visual Basic 6.0",
    src: visualBasic,
    text: "Coleccion de proyectos realizados con Visual Basic programados en Basic",
    url: "https://www.edeptec.com/p/proyectos-con-visual-basic.html",
    btnText: "Ver lista de proyectos",
  },
  {
    title: "Proyectos con Arduino",
    src: arduinoProjects,
    text: "Coleccion de proyectos realizados con arduino programados en C++",
    url: "https://www.edeptec.com/p/proyectos-con-arduino.html",
    btnText: "Ver lista de proyectos",
  },
  {
    title: "Proyectos con PIC",
    src: picProjects,
    text: "Coleccion de proyectos realizados con pic programados en Basic",
    url: "https://www.edeptec.com/p/proyectos-con-pic.html",
    btnText: "Ver lista de proyectos",
  },
];

export { projectsDb, projectsListsDb };
