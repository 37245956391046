import {
    FaFacebookF,
    FaInstagram,
    FaLinkedinIn,
    FaGithub,
  } from "react-icons/fa6";
function SocialIcons() {
  return (
    <>
      <a className="social-icon" href="https://www.facebook.com/esteban.carrillo">
        <FaFacebookF />
      </a>
      <a className="social-icon" href="https://www.instagram.com/estexav096">
        <FaInstagram />
      </a>
      <a className="social-icon" href="https://www.linkedin.com/in/esteban-carrillo">
        <FaLinkedinIn />
      </a>
      <a className="social-icon" href="https://github.com/estebancarrillog">
        <FaGithub />
      </a>
    </>
  );
}

export default SocialIcons;
