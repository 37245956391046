import "../stylesheets/Nav.css";
import NavItemButton from "./NavItemButton";
import SocialIcons from "./SocialIcons";

function Nav() {
  return (
    <nav className="nav">
      <div className="nav__container container">
        
        <input type="checkbox" id="menu" className="nav__input" />
        <label className="nav__toggle" htmlFor="menu">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </label>
        <p className="nav__title">Esteban Carrillo</p>
        <div className="nav__content">
          <ul className="nav__list container">
            <li className="nav__item">
              <NavItemButton text="Sobre mi" location="#about" />
            </li>
            <li className="nav__item">
              <NavItemButton text="Skills" location="#skills" />
            </li>
            <li className="nav__item">
              <NavItemButton text="Proyectos" location="#projects" />
            </li>
            <li className="nav__item">
              <NavItemButton text="Certificados" location="#certificates" />
            </li>
          </ul>

          <div className="nav__media container">
            <SocialIcons/>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
