import "../stylesheets/AboutMe.css";
import esteban from "../assets/section-images/esteban-picture.jpeg";

function About({className, trigger }) {
  return (
    <section className={className} id="about">
      <div className={"about-me container section-container" + (trigger ? " about-animation" : "")}>
      <h1 className="about-title">About Me</h1>
        <div className="about-container">
          <div className="about-img">
          <picture className="about-picture">
            <img src={esteban} alt="" />
          </picture>
          </div>
          <p className="about-text">
          Soy tecnólogo en electrónica, con una pasión inquebrantable por la innovación y un compromiso sólido con la exploración continua. Mi carrera se ha forjado sobre una base de curiosidad insaciable y una habilidad para transformar el conocimiento en soluciones prácticas y efectivas.
            <br />
            <br />
            La programación es mi herramienta creativa, donde convierto ideas en soluciones tangibles que generan un impacto real. A lo largo de mi trayectoria, he superado desafíos técnicos y he demostrado una evolución constante en mi campo.
            <br />
            <br />
            Más allá del ámbito tecnológico, el ciclismo y el running son pilares fundamentales en mi vida. Estas actividades no solo me mantienen activo, sino que también refuerzan mi creencia en la importancia de un equilibrio entre el intelecto y el bienestar físico.
            <br />
            <br />
            Me guío por el lema: "Learn, teach, run and ride". Creo profundamente en el aprendizaje continuo, en compartir conocimientos y en la búsqueda de una vida equilibrada y significativa.
            <br />
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
